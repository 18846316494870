import React from 'react';
import { graphql, StaticQuery } from 'gatsby';
import 'bootstrap';

import styles from '../styles/components/blog-preview.module.scss';


export default () => (
  <StaticQuery 
    query={graphql`{
      allMarkdownRemark(filter: { frontmatter: { template: { eq: "blog-page" }}}, limit: 6
      ) {
        edges {
          node {
            id
            fields {
              slug
            }
            frontmatter{
              template
              title
              description
              featured_image
            }
          }
        }
      }
    }`
  }
  render={data => (
    data.allMarkdownRemark.edges.map((item, index) => {
      return (
        <a href={item.node.fields.slug} className={`${styles.blogItem} d-flex col-md-4`} key={index}>
          <div className={`card mt-3`}>
            <img className={`img-fluid`} src={item.node.frontmatter.featured_image} alt=''/>
            <div className={`card-body`}>
              <div className={`blog-details`}>
                <h5 className={`blog-title card-text pt-3`}>{item.node.frontmatter.title}</h5>
                <p className={`${styles.blogDetail} card-text`}>{item.node.frontmatter.description}
                Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s
                </p>
              </div>
            </div>
          </div>
        </a>
      );
    })
  )}
  />
)
