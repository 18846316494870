import React from 'react';
import Header from '../components/header';
import Footer from '../components/footer'
import 'bootstrap';
import { graphql, StaticQuery } from 'gatsby';
import BlogPreview from '../components/blog-preview';
import Seo from '../components/seo'

class Main extends React.Component {
  
  render() {
    return (
      <div>
        <Header />
        <StaticQuery 
          query={graphql`{
            site {
              siteMetadata {
                title
                description
              }
            }
            markdownRemark(frontmatter: { template: { eq: "home-page" }}) {
              html
              frontmatter {
                title
                red_track
                template
                type
                description
                banner_image
              }
            } 
          }`
        }
        render={data => (
          <>
            <Seo data={data} location={this.props.location} />
            <div className="intro-image jumbotron col-12 mb-5" style={{background: `url(${data.markdownRemark.frontmatter.banner_image})`, height: "350px; !important"}}></div>
            <div className="container">
              <div className="row home-intro text-left">
                <div className="container">
                  <h1 className="text-center">{data.markdownRemark.frontmatter.title}</h1>
                  <p className="text-center">{data.markdownRemark.frontmatter.description}</p>
                  <hr className='mt-5 mb-3'></hr>
                  <div dangerouslySetInnerHTML={{ __html: data.markdownRemark.html }}></div>
                </div>
              </div>
            </div> 
            <div className='container col-xs-12'>
              <div className="row blog-content mt-3 mb-5">
                <BlogPreview />
              </div>
            </div>
          </>
        )}
        />
        <Footer />
      </div>
    );
  }
}

export default Main;
